<template>
  <div class="video">
    <HeaderHot :title="'资本影视'" :currentIndex="4"></HeaderHot>
    <Breadcrumb :navTitle="'资本影视'"></Breadcrumb>
    <div class="video-top">
      <div class="top-video">
        <video
          :src="itemData.tuiList[0].attrfile"
          controls="controls"
          class="v"
        ></video>
        <span class="text" :title="itemData.tuiList[0].title">{{
          itemData.tuiList[0].title
        }}</span>
      </div>

      <div class="top-img" v-if="false">
        <img :src="BaseUrl + itemData.tuiList[0].image" alt="" class="v" />
        <span class="text" :title="itemData.tuiList[0].title">{{
          itemData.tuiList[0].title
        }}</span>
      </div>
      <div>
        <span
          v-for="(item, index) in itemData.tuiList.slice(1, 8)"
          :key="item.id"
          :title="item.title"
          @click="goVideoDetails(item.id)"
          >{{ index + 1 }}. {{ item.title }}</span
        >
      </div>
    </div>
    <div class="video-content">
      <div
        class="video-item"
        v-for="item in itemList"
        :key="item.id"
        @click="goVideoDetails(item.id)"
      >
        <img :src="item.pcimage" alt="" />
        <span :title="item.title">{{ item.title }}</span>
      </div>
    </div>
    <div class="pageDown">
      <el-pagination
        background
        prev-text="上一页"
        next-text="下一页"
        @prev-click="pagePrev"
        @next-click="pageNext"
        @current-change="pageChange"
        layout=" prev, pager, next"
        page-size="9"
        :total="totalPage"
      >
      </el-pagination>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderHot from "../../../components/HeaderHot.vue";
import Breadcrumb from "../../../components/Breadcrumb.vue";
import Footer from "../../../components/Footer.vue";
export default {
  name: "Video",
  data() {
    return {
      TopItem: [""],
      page: 1,
      pageSize: 9,
      totalPage: "",
      itemData: [],
      itemList: [],
    };
  },
  created() {
    this.getData();
    this.getList();
  },
  methods: {
    goDetails() {
      this.$router.push("/videodetails");
    },
    // 获取资本影视页面数据
    getData() {
      this.$http({
        url: "/yingshi/index",
      }).then((res) => {
        console.log(res, "res");
        this.itemData = res.data;
      });
    },
    // 获取资本影视列表
    getList(page) {
      this.$http({
        url: "/yingshi/list",
        params: {
          page: page ? page : this.page,
          pagesize: this.pageSize,
        },
      }).then((res) => {
        console.log(res, "res");
        this.itemList = res.data.list;
        this.totalPage = res.data.total;
      });
    },
    // 上一页
    pagePrev() {
      this.page--;
      this.getList(this.page);
    },
    // 下一页
    pageNext() {
      console.log("下一页");
      this.page++;
      this.getList(this.page);
    },
    // 页面改变
    pageChange(e) {
      console.log(e);
      this.page = e;
      this.getList(this.page);
    },
    // 跳转资本影视详情
    goVideoDetails(id) {
      this.$router.push(`/videodetails/${id}`);
    },
  },
  components: {
    HeaderHot,
    Breadcrumb,
    Footer,
  },
};
</script>

<style lang="less" scoped>
.video {
  .video-top {
    display: flex;
    width: 1200px;
    margin: 0 auto 60px;
    .top-video,
    .top-img {
      position: relative;
      width: 605px;
      height: 338px;
      .v {
        position: absolute;
        width: 605px;
        height: 338px;
      }
      .text {
        position: absolute;
        width: 605px;
        height: 40px;
        bottom: 0;
        line-height: 40px;
        text-indent: 0.5em;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.3);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-indent: 0.5em;
      }
    }
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      width: 596px;
      height: 338px;
      padding: 30px 0 0 41px;
      box-sizing: border-box;
      font-size: 16px;
      background-color: rgb(42, 106, 190);
      color: #fff;
      > span {
        margin-bottom: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
      }
      span:hover {
        text-decoration: underline;
      }
    }
  }
  .video-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto -30px;
    // .video-item:nth-child(1n+1){
    //     margin:0 0px  40px 20px ;
    // }
    .video-item {
      width: 380px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 0px 40px 0px;
      box-sizing: border-box;
      cursor: pointer;
      > img:nth-child(1) {
        width: 380px;
        height: 231px;
        margin-bottom: 23px;
      }
      > span:nth-child(2) {
        font-size: 18px;
        color: #333333;
      }
      span:nth-child(2):hover {
        text-decoration: underline;
      }
    }
  }
  .pageDown {
    width: 1200px;
    margin: 80px auto 0px;
    text-align: center;
  }
}
</style>
